<template>
  <div class="row mx-0 h-100">
    <div class="h-100 relative p-0" ref="theImage">
      <Image :imageName="project.imageUrl" :width="theImageWidth" :height="theImageHeight" theClass="d-block w-100 slider_img" :alt="project.name" />
      <!-- <img :src="url + '/static/uploads/images/' + project.imageUrl" class="d-block w-100 slider_img" :alt="project.name" /> -->
      <div class="overlay">
      </div>
      <div class="row mx-0 h-100 align-items-end abs">
        <div class="price bold" v-if="project.fixed">{{defaultCurrency}}{{project.cost}} {{project.costType.description}}</div>
        <div class="price bold" v-else-if="project.suggestedPrice">{{defaultCurrency}}{{project.cost}} {{project.costType.description}}</div>
        <div class="col-12 p-md-35">
          <div class="row align-items-center justify-content-between">
            <div class="col-12 title mb-3">
              {{project.name}}
            </div>
            <div class="col">
              <Button color="gold" :btnText="btn" icon="arrow" @buttonClicked="goToProject">
                <IconArrowForward color="black" />
              </Button>
            </div>
            <div class="col-auto text-end" v-if="project.section18ATAX">
              <Button color="black_op" size="xsmall" btnText="18A" icon2="arrow" class="white-text" width="100" alignment="justify-content-between">
                <template v-slot:iconStart>
                  <ios-checkmark-circle-icon class="gold-text inline-icon"/>
                </template>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    'ios-checkmark-circle-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark-circle.vue'))
  },
  name: 'AppealCardSimple',
  props: ['project', 'btn'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      donation: {
        amount: '',
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      },
      donationType: '',
      isAdded: false,
      url: process.env.VUE_APP_URL_BASE,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
    this.donation.projectId = this.project.projectID
    this.donation.currency = this.defaultCurrency
  },
  computed: {
    date () {
      const date = DateTime.fromMillis(this.project.lastModified)
      return date.toLocaleString(DateTime.DATE_FULL)
    }
  },
  methods: {
    ...mapActions([
      'addToCart',
      'clearCart'
    ]),
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    },
    goToProject () {
      window.scroll(0, 0)
      this.$router.push('/project/' + this.project.id)
    },
    goDonate () {
      this.addDonationToCart()
      this.$router.push('/checkout/0')
    },
    addDonationToCart () {
      this.project.projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType !== this.donationType) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: this.donationType, amount: this.donation.amount })
        }
      })
      this.addToCart(this.donation)
      this.isAdded = true
      this.donation = {
        amount: '',
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      }
      this.selectedProject = {}
      setTimeout(() => {
        this.isAdded = false
      }, 2000)
    }
  }
}
</script>

<style scoped>

.price {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: var(--green-color-dark);
  padding: 0.5rem 1rem;
  color: var(--gold-color);
  border-radius: 37px;
  width: auto;
  font-size: 0.8rem;
}
  .imageSlider-item {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .box_shadow {
    box-shadow: 0px 15px 24px 0px rgb(0 0 0 / 21%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 15px 15px 15px 15px;
    padding: 45px 45px 45px 45px;
    border-radius: 15px;
  }
  .height {
    height: 35vh;
  }
  .slider_img  {
    object-fit: cover;
    width: 100%;
    height: 279px;
    border-radius: 15px;
  }
  .p-md-35 {
    padding: 25px;
  }
  .overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg, #00000000 17%, #000000 100%);
    opacity: 0.81;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 15px;
  }
  .abs {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .title {
    color: #FFFFFF;
    font-family: "quicksand_medium", Sans-serif;
    font-size: 1rem;
    font-weight: 600;
  }
@media (min-width: 992px) {
  .title {
    font-size: 1.62rem;
  }
  .price {
    position: absolute;
    top: 35px;
    left: 35px;
    background-color: var(--green-color-dark);
    padding: 7px 17px;
    color: var(--gold-color);
    border-radius: 37px;
    width: auto;
    font-size: 1rem;
  }
  .slider_img  {
    height: 438px;
  }
  .p-md-35 {
    padding: 35px;
  }
}

</style>
